import React, { ReactElement } from 'react';
import { BlogPostModel } from '../../models';
import { PostItem } from './post-item';

interface PostsProps {
  posts: BlogPostModel[];
}

export const Posts = ({ posts }: PostsProps): ReactElement =>
  posts.length > 0 ? (
    <ul className="posts-list gap-4 flex flex-col">
      {posts.map((post) => (
        <PostItem key={post.id} {...post} />
      ))}
    </ul>
  ) : (
    <p>No posts are available, please check soon!</p>
  );
