import React from 'react';
import { H4 } from '../design/typography';

interface SimpleSectionProps {
  title: string;
}

export const SimpleSection: React.FC<SimpleSectionProps> = ({ title, children }) => (
  <section className="mb-6">
    <header className="border-b border-gray-300 mb-3 pb-2 border-b-2">
      <H4 withMargins={false}>{title}</H4>
    </header>
    {children}
  </section>
);
