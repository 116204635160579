import React, { ReactElement } from 'react';
import { BlogPostModel } from '../../models';
import { Link } from 'gatsby';
import { TagList } from '../tag-list';
import { PillVariant } from '../pills/pill';
import { Avatar } from '../avatar';

export const PostItem = ({
  title,
  tags,
  slug,
  description,
  timeToRead,
  date,
}: BlogPostModel): ReactElement => (
  <li className="w-full gap-2 pb-4 border-b sm:border-0">
    <h3 className="mb-2">
      <Link
        to={slug}
        className="block w-full font-medium font-sans text-xl hover:underline hover:text-main-light"
      >
        {title}
      </Link>
    </h3>
    <p className="text-sm font-body text-gray-700">{description}</p>
    <footer className="text-xs text-gray-500 flex pt-2 gap-2 sm:gap-4 flex-col sm:flex-row sm:items-center">
      <div className="flex gap-2 items-center">
        <Avatar className="w-6 h-6" />
        <span>Piotr Staniów</span>
        &bull;<span>{date}</span>
        {timeToRead && (
          <>
            &bull;<span>{timeToRead} minutes read</span>
          </>
        )}
      </div>
      <TagList tags={tags} variant={PillVariant.MODEST} />
    </footer>
  </li>
);
