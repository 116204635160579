import React from 'react';
import SEO from '../components/seo';
import { Paragraph } from '../components/design';
import { SimpleSection } from '../components/simple-section';
import { Avatar } from '../components/avatar';
import { ErrorBoundary } from '../components/error-boundary';
import { useLatestPosts } from '../hooks';
import { Posts } from '../components/posts';
import HeroLayout from '../components/layouts/hero-layout';

const IndexPage: React.FC = () => {
  const latestPosts = useLatestPosts();

  const hero = (
    <section className="flex flex-col items-center sm:flex-row-reverse justify-between">
      <ErrorBoundary scope="avatar">
        <Avatar className="w-40 h-40 sm:w-64 sm:h-64" />
      </ErrorBoundary>
      <div className="w-full sm:w-1/2 my-auto">
        <Paragraph fontFamily="sans" color="text-white">
          Hi, I'm
        </Paragraph>
        <h1 className="font-sans text-4xl sm:text-5xl my-4 px-0 text-white">Piotr Staniów</h1>
        <Paragraph fontFamily="sans" color="text-white">
          Software Engineer, Scrum Master, sharing passion for programming on blog and various tech
          talks.
          <br />
          <br />
          <small>
            Currently working as Senior Software Engineer at{' '}
            <a href="https://www.hubspot.com/">HubSpot</a>
          </small>
        </Paragraph>
      </div>
    </section>
  );

  return (
    <HeroLayout hero={hero}>
      <SEO title="Home" />
      <SimpleSection title="Recent articles">
        <Posts posts={latestPosts} />
      </SimpleSection>
    </HeroLayout>
  );
};

export default IndexPage;
